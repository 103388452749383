import { CopartWinner } from 'Models/CopartWinner'
import { extensionWonGetters } from './types'

export default {
  [extensionWonGetters.IS_LOADING]: state => state.isLoading,
  [extensionWonGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [extensionWonGetters.PAGE_NUMBER]: state => state.pageNumber,
  [extensionWonGetters.FETCH_FN]: state => state.fetchFn,

  [extensionWonGetters.LIST]: state => state.list
    .map(item => new CopartWinner(item)),
  [extensionWonGetters.TOTAL_COUNT]: state => state.totalCount,

  [extensionWonGetters.EXPORT_LIST]: state => state.exportList
    .map(item => new CopartWinner(item)),
  [extensionWonGetters.LIST_OPTS]: state => state.listOpts,

  [extensionWonGetters.MANAGED_LOGINS]: state => state.managedLogins,
}
