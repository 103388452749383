import { extensionWonActions, extensionWonGetters, extensionWonMutations } from './types'
import { EXTENSION_WON_PAGE_LIMIT } from '../constants'

import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { sdk } from 'Services/shelfNetworkSdk'

const loneSdkCall = new LoneSdkCall()

export default {
  async [extensionWonActions.LOAD_LIST] (
    { commit, dispatch },
    { action, opts }
  ) {
    commit(extensionWonMutations.SET_IS_LOADING, true)
    commit(extensionWonMutations.SET_PAGE_NUMBER, 0)
    commit(extensionWonMutations.SET_LIST_OPTS, opts)

    await dispatch(action, opts)

    commit(extensionWonMutations.SET_IS_LOADING, false)
  },

  async [extensionWonActions.LOAD_NOT_PAID_LIST] ({ commit, getters }, opts) {
    const fetchFn = opts => loneSdkCall.takeLatest(
      sdk.copartBackOffice.getInvoicesV2({
        query: {
          ...opts,
          filter: {
            ...(opts.filter || {}),
            isPaid: false
          },
          page: {
            limit: EXTENSION_WON_PAGE_LIMIT,
            order: 'asc',
            ...(opts.page || {}),
          },
          include: ['identity', 'identity.basics', 'participation', 'vehicle', 'daily_stats']
        }
      })
    )

    const { data, meta } = await fetchFn({
      ...opts,
      page: { number: getters[extensionWonGetters.PAGE_NUMBER] }
    })

    commit(extensionWonMutations.SET_LIST, data)
    commit(extensionWonMutations.SET_TOTAL_COUNT, meta.totalCount || 0)
    commit(extensionWonMutations.SET_FETCH_FN, fetchFn)
  },

  async [extensionWonActions.LOAD_WON_LIST] ({ commit, getters }, opts) {
    const fetchFn = opts => loneSdkCall.takeLatest(
      sdk.copartBackOffice.getInvoicesV2({
        query: {
          ...opts,
          page: {
            limit: EXTENSION_WON_PAGE_LIMIT,
            ...(opts.page || {}),
          },
          include: ['identity', 'identity.basics', 'participation', 'vehicle', 'daily_stats']
        }
      })
    )

    const { data, meta } = await fetchFn({
      ...opts,
      page: { number: getters[extensionWonGetters.PAGE_NUMBER] }
    })

    commit(extensionWonMutations.SET_LIST, data)
    commit(extensionWonMutations.SET_TOTAL_COUNT, meta.totalCount || 0)
    commit(extensionWonMutations.SET_FETCH_FN, fetchFn)
  },

  async [extensionWonActions.LOAD_OPEN_ITEMS_LIST] ({ commit, getters }, opts) {
    const fetchFn = opts => loneSdkCall.takeLatest(
      sdk.copartBackOffice.getOpenItems({
        query: {
          ...opts,
          page: {
            limit: EXTENSION_WON_PAGE_LIMIT,
            ...(opts.page || {}),
          },
          include: ['identity', 'identity.basics', 'participation', 'vehicle']
        }
      })
    )

    const { data, meta } = await fetchFn({
      ...opts,
      page: { number: getters[extensionWonGetters.PAGE_NUMBER] }
    })

    commit(extensionWonMutations.SET_LIST, data)
    commit(extensionWonMutations.SET_TOTAL_COUNT, meta.totalCount || 0)
    commit(extensionWonMutations.SET_FETCH_FN, fetchFn)
  },

  async [extensionWonActions.LOAD_HISTORY_LIST] ({ commit, getters }, opts) {
    const fetchFn = opts => loneSdkCall.takeLatest(
      sdk.copartBackOffice.getPaidInvoicesV2({
        query: {
          ...opts,
          page: {
            limit: EXTENSION_WON_PAGE_LIMIT,
            ...(opts.page || {}),
          },
          include: ['identity', 'identity.basics', 'participation', 'vehicle', 'daily_stats']
        }
      })
    )

    const { data, meta } = await fetchFn({
      ...opts,
      page: { number: getters[extensionWonGetters.PAGE_NUMBER] }
    })

    commit(extensionWonMutations.SET_LIST, data)
    commit(extensionWonMutations.SET_TOTAL_COUNT, meta.totalCount || 0)
    commit(extensionWonMutations.SET_FETCH_FN, fetchFn)
  },

  async [extensionWonActions.LOAD_EXPORT_LIST] ({ commit, getters }) {
    const opts = getters[extensionWonGetters.LIST_OPTS]
    const fetchFn = getters[extensionWonGetters.FETCH_FN]
    if (!fetchFn) return

    const EXPORT_PAGE_LIMIT = 100
    const list = []

    let pageNumber = 0
    let fetchNextFn
    while (true) {
      const { data, fetchNext } = fetchNextFn
        ? await fetchNextFn()
        : await fetchFn({
          ...opts,
          page: {
            number: pageNumber++,
            limit: EXPORT_PAGE_LIMIT
          },
        })

      fetchNextFn = fetchNext
      list.push(...data)
      if (data.length < EXPORT_PAGE_LIMIT) break
    }

    commit(extensionWonMutations.SET_EXPORT_LIST, list)
  },

  async [extensionWonActions.LOAD_MORE] ({ commit, getters }, opts) {
    const fetchFn = getters[extensionWonGetters.FETCH_FN]
    const pageNumber = getters[extensionWonGetters.PAGE_NUMBER]
    if (!fetchFn || pageNumber === -1) return

    commit(extensionWonMutations.SET_IS_NEXT_LOADING, true)

    commit(extensionWonMutations.SET_PAGE_NUMBER, pageNumber + 1)

    const { data } = await fetchFn({
      ...opts,
      page: { number: getters[extensionWonGetters.PAGE_NUMBER] }
    })

    commit(extensionWonMutations.PUSH_LIST, data)

    if (data.length < EXTENSION_WON_PAGE_LIMIT) {
      commit(extensionWonMutations.SET_PAGE_NUMBER, -1)
    }

    commit(extensionWonMutations.SET_IS_NEXT_LOADING, false)
  },

  async [extensionWonActions.UPDATE_LOT_SALE] (_, opts) {
    await sdk.copartBackOffice.updateLotSale(opts)
  },

  async [extensionWonActions.LOAD_MANAGED_LOGINS] ({ commit }) {
    const { data: config } = await sdk.copartBackOffice.getConfig({
      query: {
        // HACK: Hardcode version for admin panel
        filter: { version: '333.0.0' }
      }
    })

    commit(
      extensionWonMutations.SET_MANAGED_LOGINS,
      config.allowedToAssignTo
    )
  },

  [extensionWonActions.SET_IS_LOADING] ({ commit }, value) {
    commit(extensionWonMutations.SET_IS_LOADING, value)
  }
}
